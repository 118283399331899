export const videos = {
  kurta: ['n2Hx6A68bnk', 'DdfJeT8KFuk'],
  anarkali: ['q4hF0NetEok'],
  blouse: ['1YHp22hcUJg', '9tQX1M8qmSI'],
  bridesmaids: ['1YHp22hcUJg'],
  lehanga: ['1YHp22hcUJg', '9tQX1M8qmSI'],
  palazzo: ['1YHp22hcUJg', '9tQX1M8qmSI'],
  thankyou: ['zYLfb_HxMZ4'],
};

export const anarkali = {
  'Upper Chest': {
    db: 'upperChest',
  },
  Chest: {
    db: 'chest',
  },
  'Lower Chest': {
    db: 'lowerChest',
  },
  'Body Length': {
    db: 'bodyLength',
  },
  Shoulder: {
    db: 'shoulder',
  },
  Waist: {
    db: 'waist',
  },
  Hips: {
    db: 'hips',
  },
  'Sleeve Length': {
    db: 'sleeveLength',
  },
  Pads: {
    db: 'pads',
  },
  'Sleeve Round': {
    db: 'sleeveRound',
  },
  Underarm: {
    db: 'underarm',
  },
  'Neck Depth Front': {
    db: 'neckDepthFront',
  },
  'Neck Depth Back': {
    db: 'neckDepthBack',
  },
  Length: {
    db: 'length',
  },
  'Extra Notes': {
    db: 'extraNotes',
  },
};

export const kurta = {
  Chest: {
    db: 'chest',
  },
  Waist: {
    db: 'waist1',
  },
  Hips: {
    db: 'hips1',
  },
  Shoulder: {
    db: 'shoulder',
  },
  'Sleeve Length': {
    db: 'sleeveLength',
  },
  'Sleeve Round': {
    db: 'sleeveRound',
  },
  Underarm: {
    db: 'underarm',
  },
  Neck: {
    db: 'neck',
  },
  Wrist: {
    db: 'wrist',
  },
  Length: {
    db: 'topLength',
  },
  'Length (Bottom)': {
    db: 'bottomLength',
  },
  'Waist (Bottom)': {
    db: 'waist2',
  },
  'Hips (Bottom)': {
    db: 'hips2',
  },
  Thigh: {
    db: 'thigh',
  },
  Knee: {
    db: 'knee',
  },
  Calf: {
    db: 'calf',
  },
  Ankle: {
    db: 'ankle',
  },
  Rise: {
    db: 'rise',
  },
  Height: {
    db: 'height',
  },
  'American Suit Size': {
    db: 'suitSize',
  },
  'American Shoe Size': {
    db: 'shoeSize',
  },
  'Extra Notes': {
    db: 'extraNotes',
  },
};

export const blouse = {
  'Upper Chest': {
    db: 'upperChest',
  },
  Chest: {
    db: 'chest',
  },
  'Lower Chest': {
    db: 'lowerChest',
  },
  'Blouse Length': {
    db: 'blouseLength',
  },
  Shoulder: {
    db: 'shoulder',
  },
  Waist: {
    db: 'waist1',
  },
  'Sleeve Length': {
    db: 'sleeveLength',
  },
  'Sleeve Round': {
    db: 'sleeveRound',
  },
  Underarm: {
    db: 'underarm',
  },
  Wrist: {
    db: 'wrist',
  },
  'Neck Depth Front': {
    db: 'neckDepthFront',
  },
  'Neck Depth Back': {
    db: 'neckDepthBack',
  },
  Pads: {
    db: 'pads',
  },
  'Waist (Bottom)': {
    db: 'waist2',
  },
  Hips: {
    db: 'hips',
  },
  'Length With Heels': {
    db: 'lengthWithHeels',
  },
  'Extra Notes': {
    db: 'extraNotes',
  },
};

export const palazzo = {
  'Upper Chest': {
    db: 'upperChest',
  },
  Chest: {
    db: 'chest',
  },
  'Lower Chest': {
    db: 'lowerChest',
  },
  'Blouse Length': {
    db: 'blouseLength',
  },
  Shoulder: {
    db: 'shoulder',
  },
  Waist: {
    db: 'waist1',
  },
  'Sleeve Length': {
    db: 'sleeveLength',
  },
  'Sleeve Round': {
    db: 'sleeveRound',
  },
  Underarm: {
    db: 'underarm',
  },
  Wrist: {
    db: 'wrist',
  },
  'Neck Depth Front': {
    db: 'neckDepthFront',
  },
  'Neck Depth Back': {
    db: 'neckDepthBack',
  },
  Pads: {
    db: 'pads',
  },
  'Waist (Bottom)': {
    db: 'waist2',
  },
  Hips: {
    db: 'hips',
  },
  'Length With Heels': {
    db: 'lengthWithHeels',
  },
  Thigh: {
    db: 'thigh',
  },
  Rise: {
    db: 'rise',
  },
  'Extra Notes': {
    db: 'extraNotes',
  },
};

export const bridesmaids = {
  'Upper Chest': {
    db: 'upperChest',
  },
  Chest: {
    db: 'chest',
  },
  'Lower Chest': {
    db: 'lowerChest',
  },
  'Blouse Length': {
    db: 'blouseLength',
  },
  Shoulder: {
    db: 'shoulder',
  },
  Waist: {
    db: 'waist',
  },
  'Sleeve Length': {
    db: 'sleeveLength',
  },
  'Sleeve Round': {
    db: 'sleeveRound',
  },
  Underarm: {
    db: 'underarm',
  },
  'Neck Depth Front': {
    db: 'neckDepthFront',
  },
  'Neck Depth Back': {
    db: 'neckDepthBack',
  },
  Pads: {
    db: 'pads',
  },
  'Extra Notes': {
    db: 'extraNotes',
  },
};

export const lehanga = {
  'Upper Chest': {
    db: 'upperChest',
  },
  Chest: {
    db: 'chest',
  },
  'Lower Chest': {
    db: 'lowerChest',
  },
  'Blouse Length': {
    db: 'blouseLength',
  },
  Shoulder: {
    db: 'shoulder',
  },
  Waist: {
    db: 'waist1',
  },
  'Sleeve Length': {
    db: 'sleeveLength',
  },
  'Sleeve Round': {
    db: 'sleeveRound',
  },
  Underarm: {
    db: 'underarm',
  },
  'Neck Depth Front': {
    db: 'neckDepthFront',
  },
  'Neck Depth Back': {
    db: 'neckDepthBack',
  },
  Pads: {
    db: 'pads',
  },
  'Waist (Bottom)': {
    db: 'waist2',
  },
  Hips: {
    db: 'hips',
  },
  'Length With Heels': {
    db: 'lengthWithHeels',
  },
  'Extra Notes': {
    db: 'extraNotes',
  },
};
