import {
  Box,
  FormGroup,
  Grid,
  TextField,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Modal,
  Button,
  List,
  ListItem,
} from '@mui/material';
import rise from './assets/images/images.jpeg';
import toast, {Toaster} from 'react-hot-toast';
import React, {useContext, useState} from 'react';
import './styles.css';
import {useForm} from 'react-hook-form';
import {GlobalContext} from '../../../../../../context/GlobalContext';
import axios from 'axios';
import CircleIcon from '@mui/icons-material/Circle';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

const schema = yup.object().shape({
  knee: yup
    .number('Knee should be a number.')
    .positive('Knee should be a number.')
    .integer('Knee should be a number.')
    .required('Knee should be a number.')
    .typeError('Knee should be a number.'),

  calf: yup
    .number('Calf should be a number.')
    .positive('Calf should be a number.')
    .integer('Calf should be a number.')
    .required('Calf should be a number.')
    .typeError('Calf should be a number.'),
  ankle: yup
    .number('Ankle should be a number.')
    .positive('Ankle should be a number.')
    .integer('Ankle should be a number.')
    .required('Ankle should be a number.')
    .typeError('Ankle should be a number.'),
  bottomLength: yup
    .number('Bottom Length should be a number.')
    .positive('Bottom Length should be a number.')
    .integer('Bottom Length should be a number.')
    .required('Bottom Length should be a number.')
    .typeError('Bottom Length should be a number.'),
  chest: yup
    .number('Chest should be a number.')
    .positive('Chest should be a number.')
    .integer('Chest should be a number.')
    .required('Chest should be a number.')
    .typeError('Chest should be a number.'),
  height1: yup
    .number('Height should be a number.')
    .positive('Height should be a number.')
    .integer('Height should be a number.')
    .required('Height should be a number.')
    .typeError('Height should be a number.'),
  height2: yup
    .number('Height should be a number.')
    .positive('Height should be a number.')
    .integer('Height should be a number.')
    .required('Height should be a number.')
    .typeError('Height should be a number.'),
  hips1: yup
    .number('Hips should be a number.')
    .positive('Hips should be a number.')
    .integer('Hips should be a number.')
    .required('Hips should be a number.')
    .typeError('Hips should be a number.'),
  hips2: yup
    .number('Hips should be a number.')
    .positive('Hips should be a number.')
    .integer('Hips should be a number.')
    .required('Hips should be a number.')
    .typeError('Hips should be a number.'),
  neck: yup
    .number('Neck should be a number.')
    .positive('Neck should be a number.')
    .integer('Neck should be a number.')
    .required('Neck should be a number.')
    .typeError('Neck should be a number.'),
  rise: yup
    .number('Rise should be a number.')
    .positive('Rise should be a number.')
    .integer('Rise should be a number.')
    .required('Rise should be a number.')
    .typeError('Rise should be a number.'),
  shoulder: yup
    .number('Shoulder should be a number.')
    .positive('Shoulder should be a number.')
    .integer('Shoulder should be a number.')
    .required('Shoulder should be a number.')
    .typeError('Shoulder should be a number.'),
  sleeveLength: yup
    .number('Sleeve Length should be a number.')
    .positive('Sleeve Length should be a number.')
    .integer('Sleeve Length should be a number.')
    .required('Sleeve Length should be a number.')
    .typeError('Sleeve Length should be a number.'),
  sleeveRound: yup
    .number('Sleeve Round should be a number.')
    .positive('Sleeve Round should be a number.')
    .integer('Sleeve Round should be a number.')
    .required('Sleeve Round should be a number.')
    .typeError('Sleeve Round should be a number.'),
  suitSize: yup
    .number('First field should be a number.')
    .positive('First field should be a number.')
    .integer('First field should be a number.')
    .required('First field should be a number.')
    .typeError('First field should be a number.'),
  suitSize2: yup
    .string()
    .oneOf(['R', 'S', 'r', 's'], 'Second field must be R or S.')
    .required('Second field must be R or S.'),
  thigh: yup
    .number('Thigh should be a number.')
    .positive('Thigh should be a number.')
    .integer('Thigh should be a number.')
    .required('Thigh should be a number.')
    .typeError('Thigh should be a number.'),
  topLength: yup
    .number('Top Length should be a number.')
    .positive('Top Length should be a number.')
    .integer('Top Length should be a number.')
    .required('Top Length should be a number.')
    .typeError('Top Length should be a number.'),
  underarm: yup
    .number('Underarm should be a number.')
    .positive('Underarm should be a number.')
    .integer('Underarm should be a number.')
    .required('Underarm should be a number.')
    .typeError('Underarm should be a number.'),
  waist1: yup
    .number('Waist should be a number.')
    .positive('Waist should be a number.')
    .integer('Waist should be a number.')
    .required('Waist should be a number.')
    .typeError('Waist should be a number.'),
  waist2: yup
    .number('Waist should be a number.')
    .positive('Waist should be a number.')
    .integer('Waist should be a number.')
    .required('Waist should be a number.')
    .typeError('Waist should be a number.'),
  wrist: yup
    .number('Wrist should be a number.')
    .positive('Wrist should be a number.')
    .integer('Wrist should be a number.')
    .required('Wrist should be a number.')
    .typeError('Wrist should be a number.'),
  name: yup.string().required('Name is required.'),
  email: yup
    .string()
    .email('Wrong email format.')
    .required('Email is required.'),
});

const Kurta = ({setSubmit}) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const {clientId} = useContext(GlobalContext);
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm({resolver: yupResolver(schema)});
  const onSubmit = async (data) => {
    data.suitSize = data.suitSize + data.suitSize2;
    const formData = {
      reference_client_id: clientId,
      email: data.email,
      name: data.name,
      measurements: {...data},
    };
    await axios.post('/api/friend/register', {formData}).then((res) => {
      if (res.data.data) {
        toast.error(res.data.data.message, {
          style: {
            color: 'white',
          },
        });
      } else {
        if (res.data.code == 200) {
          setSubmit(true);
        } else {
          toast.error(
            'There was an error saving your measurements. Try again!',
            {
              style: {
                color: 'white',
              },
            }
          );
        }
      }
    });
  };
  return (
    <>
      <Toaster
        position="top-right"
        toastOptions={{
          success: {
            style: {
              background: 'green',
            },
          },
          error: {
            style: {
              background: 'red',
            },
          },
        }}
      />
      <Box sx={{paddingBottom: '5%'}}>
        <Grid container marginTop={'5%'} justifyContent="center">
          <Grid item xs={10} sm={8} md={12} marginTop={'2%'}>
            <Typography variant="h6" sx={{fontWeight: 700}}>
              Note:
            </Typography>
            <List sx={{display: 'list-item', listStyle: 'none'}}>
              <ListItem>
                <CircleIcon sx={{height: '10px'}} />
                <Typography variant="p">
                  Please put all measurements in inches
                </Typography>
              </ListItem>
              <ListItem>
                <CircleIcon sx={{height: '10px'}} />
                <Typography variant="p">
                  Alterations are an additional charge
                </Typography>
              </ListItem>
              <ListItem>
                <CircleIcon sx={{height: '10px'}} />
                <Typography variant="p">
                  Kindly watch the instruction video while measuring yourself
                </Typography>
              </ListItem>
            </List>
          </Grid>
        </Grid>
        <Grid container marginTop={'5%'}>
          <Grid item xs={8} sm={6} md={4} lg={3} marginTop={'2%'}>
            <FormGroup sx={{marginLeft: '10px'}}>
              <InputLabel
                sx={{fontWeight: '700', textAlign: 'left', color: '#1b1a17'}}
              >
                Name
              </InputLabel>
              <TextField variant="standard" type="text" {...register('name')} />

              <Typography
                sx={{
                  marginTop: '3%',
                  color: 'red',
                  fontSize: '12px',
                }}
              >
                {errors.name?.message}
              </Typography>
            </FormGroup>
          </Grid>
          <Grid item xs={8} sm={6} md={4} lg={3} marginTop={'2%'}>
            <FormGroup sx={{marginLeft: '10px'}}>
              <InputLabel
                sx={{fontWeight: '700', textAlign: 'left', color: '#1b1a17'}}
              >
                Email
              </InputLabel>
              <TextField
                variant="standard"
                type="email"
                {...register('email')}
              />
              <Typography
                sx={{
                  marginTop: '3%',
                  color: 'red',
                  fontSize: '12px',
                }}
              >
                {errors.email?.message}
              </Typography>
            </FormGroup>
          </Grid>
        </Grid>
        <Grid container marginTop={'2%'}>
          <Grid item xs={12} lg={12}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        backgroundColor: '#212b36',
                        color: '#FFF',
                        border: 'none',
                        padding: '20px',
                      }}
                    >
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontWeight: '700', fontSize: '15px'}}
                      >
                        Measurements needed
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        backgroundColor: '#212b36',
                        color: '#FFF',
                        border: 'none',
                        padding: '20px',
                      }}
                    >
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontWeight: '700', fontSize: '15px'}}
                      >
                        Measurement Description
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        backgroundColor: '#212b36',
                        color: '#FFF',
                        border: 'none',
                        padding: '20px',
                      }}
                    >
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontWeight: '700', fontSize: '15px'}}
                      >
                        Measurements
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell variant="head">
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontWeight: '600', fontSize: '15px'}}
                      >
                        Chest
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontSize: '13px'}}
                      >
                        Circumference around chest
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <TextField
                        InputProps={{
                          disableUnderline: true,
                        }}
                        variant="standard"
                        {...register('chest')}
                        className="tableInput"
                        type="text"
                      />

                      <Typography
                        sx={{
                          marginTop: '3%',
                          color: 'red',
                          fontSize: '12px',

                          width: '50%',
                        }}
                      >
                        {errors.chest?.message}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontWeight: '600', fontSize: '15px'}}
                      >
                        Waist
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontSize: '13px'}}
                      >
                        Circumference around belly button
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <TextField
                        InputProps={{
                          disableUnderline: true,
                        }}
                        variant="standard"
                        {...register('waist1')}
                        className="tableInput"
                        type="text"
                      />
                      <Typography
                        sx={{
                          marginTop: '3%',
                          color: 'red',
                          fontSize: '12px',

                          width: '50%',
                        }}
                      >
                        {errors.waist1?.message}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontWeight: '600', fontSize: '15px'}}
                      >
                        Hips
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontSize: '13px'}}
                      >
                        Circumference over butt
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <TextField
                        InputProps={{
                          disableUnderline: true,
                        }}
                        variant="standard"
                        {...register('hips1')}
                        className="tableInput"
                        type="text"
                      />
                      <Typography
                        sx={{
                          marginTop: '3%',
                          color: 'red',
                          fontSize: '12px',

                          width: '50%',
                        }}
                      >
                        {errors.hips1?.message}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontWeight: '600', fontSize: '15px'}}
                      >
                        Shoulder
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontSize: '13px'}}
                      >
                        Shoulder to Shoulder
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <TextField
                        InputProps={{
                          disableUnderline: true,
                        }}
                        type="text"
                        variant="standard"
                        {...register('shoulder')}
                        className="tableInput"
                      />
                      <Typography
                        sx={{
                          marginTop: '3%',
                          color: 'red',
                          fontSize: '12px',

                          width: '50%',
                        }}
                      >
                        {errors.shoulder?.message}
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell variant="head">
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontWeight: '600', fontSize: '15px'}}
                      >
                        Sleeve length
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontSize: '13px'}}
                      >
                        From shoulder to wrist
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <TextField
                        InputProps={{
                          disableUnderline: true,
                        }}
                        variant="standard"
                        {...register('sleeveLength')}
                        className="tableInput"
                        type="text"
                      />
                      <Typography
                        sx={{
                          marginTop: '3%',
                          color: 'red',
                          fontSize: '12px',

                          width: '50%',
                        }}
                      >
                        {errors.sleeveLength?.message}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontWeight: '600', fontSize: '15px'}}
                      >
                        Sleeve Round
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontSize: '13px'}}
                      >
                        Circumference around bicep
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <TextField
                        InputProps={{
                          disableUnderline: true,
                        }}
                        variant="standard"
                        {...register('sleeveRound')}
                        className="tableInput"
                        type="text"
                      />
                      <Typography
                        sx={{
                          marginTop: '3%',
                          color: 'red',
                          fontSize: '12px',

                          width: '50%',
                        }}
                      >
                        {errors.sleeveRound?.message}
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell variant="head">
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontWeight: '600', fontSize: '15px'}}
                      >
                        Underarm/armpit
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontSize: '13px'}}
                      >
                        Circumference where arm meets shoulder
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <TextField
                        InputProps={{
                          disableUnderline: true,
                        }}
                        variant="standard"
                        {...register('underarm')}
                        className="tableInput"
                        type="text"
                      />
                      <Typography
                        sx={{
                          marginTop: '3%',
                          color: 'red',
                          fontSize: '12px',

                          width: '50%',
                        }}
                      >
                        {errors.underarm?.message}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontWeight: '600', fontSize: '15px'}}
                      >
                        Neck/Collar
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontSize: '13px'}}
                      >
                        Circumference around neck
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <TextField
                        InputProps={{
                          disableUnderline: true,
                        }}
                        variant="standard"
                        {...register('neck')}
                        className="tableInput"
                        type="text"
                      />
                      <Typography
                        sx={{
                          marginTop: '3%',
                          color: 'red',
                          fontSize: '12px',

                          width: '50%',
                        }}
                      >
                        {errors.neck?.message}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontWeight: '600', fontSize: '15px'}}
                      >
                        Wrist
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontSize: '13px'}}
                      >
                        Circumference around knuckles so the opening of the
                        sleeve can go through
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <TextField
                        InputProps={{
                          disableUnderline: true,
                        }}
                        variant="standard"
                        {...register('wrist')}
                        className="tableInput"
                        type="text"
                      />
                      <Typography
                        sx={{
                          marginTop: '3%',
                          color: 'red',
                          fontSize: '12px',

                          width: '50%',
                        }}
                      >
                        {errors.wrist?.message}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontWeight: '600', fontSize: '15px'}}
                      >
                        Top Length
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontSize: '13px'}}
                      >
                        Measure from shoulder to how ever long you want it
                        (typically- knee length)
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <TextField
                        InputProps={{
                          disableUnderline: true,
                        }}
                        variant="standard"
                        {...register('topLength')}
                        className="tableInput"
                        type="text"
                      />
                      <Typography
                        sx={{
                          marginTop: '3%',
                          color: 'red',
                          fontSize: '12px',

                          width: '50%',
                        }}
                      >
                        {errors.topLength?.message}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontWeight: '600', fontSize: '15px'}}
                      >
                        Bottom Length
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontSize: '13px'}}
                      >
                        For chudidar style, measure from belly button to floor
                        and add 5 inches
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <TextField
                        InputProps={{
                          disableUnderline: true,
                        }}
                        variant="standard"
                        {...register('bottomLength')}
                        className="tableInput"
                        type="text"
                      />
                      <Typography
                        sx={{
                          marginTop: '3%',
                          color: 'red',
                          fontSize: '12px',

                          width: '50%',
                        }}
                      >
                        {errors.bottomLength?.message}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontWeight: '600', fontSize: '15px'}}
                      >
                        Waist
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontSize: '13px'}}
                      >
                        Circumference around the waist where you like to wear
                        your pants
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <TextField
                        InputProps={{
                          disableUnderline: true,
                        }}
                        variant="standard"
                        {...register('waist2')}
                        className="tableInput"
                        type="text"
                      />
                      <Typography
                        sx={{
                          marginTop: '3%',
                          color: 'red',
                          fontSize: '12px',

                          width: '50%',
                        }}
                      >
                        {errors.waist2?.message}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontWeight: '600', fontSize: '15px'}}
                      >
                        Hips
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontSize: '13px'}}
                      >
                        Circumference over butt
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <TextField
                        InputProps={{
                          disableUnderline: true,
                        }}
                        variant="standard"
                        {...register('hips2')}
                        className="tableInput"
                        type="text"
                      />
                      <Typography
                        sx={{
                          marginTop: '3%',
                          color: 'red',
                          fontSize: '12px',

                          width: '50%',
                        }}
                      >
                        {errors.hips2?.message}
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell variant="head">
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontWeight: '600', fontSize: '15px'}}
                      >
                        Thigh
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontSize: '13px'}}
                      >
                        Circumference around one thigh
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <TextField
                        InputProps={{
                          disableUnderline: true,
                        }}
                        variant="standard"
                        {...register('thigh')}
                        className="tableInput"
                        type="text"
                      />
                      <Typography
                        sx={{
                          marginTop: '3%',
                          color: 'red',
                          fontSize: '12px',

                          width: '50%',
                        }}
                      >
                        {errors.thigh?.message}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontWeight: '600', fontSize: '15px'}}
                      >
                        Knee
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontSize: '13px'}}
                      >
                        Circumference around one knee
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <TextField
                        InputProps={{
                          disableUnderline: true,
                        }}
                        variant="standard"
                        {...register('knee')}
                        className="tableInput"
                        type="text"
                      />
                      <Typography
                        sx={{
                          marginTop: '3%',
                          color: 'red',
                          fontSize: '12px',

                          width: '50%',
                        }}
                      >
                        {errors.knee?.message}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontWeight: '600', fontSize: '15px'}}
                      >
                        Calf
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontSize: '13px'}}
                      >
                        Circumference around one calf
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <TextField
                        InputProps={{
                          disableUnderline: true,
                        }}
                        variant="standard"
                        {...register('calf')}
                        className="tableInput"
                        type="text"
                      />
                      <Typography
                        sx={{
                          marginTop: '3%',
                          color: 'red',
                          fontSize: '12px',

                          width: '50%',
                        }}
                      >
                        {errors.calf?.message}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontWeight: '600', fontSize: '15px'}}
                      >
                        Ankle
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontSize: '13px'}}
                      >
                        Circumference around ankle
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <TextField
                        InputProps={{
                          disableUnderline: true,
                        }}
                        variant="standard"
                        {...register('ankle')}
                        className="tableInput"
                        type="text"
                      />
                      <Typography
                        sx={{
                          marginTop: '3%',
                          color: 'red',
                          fontSize: '12px',

                          width: '50%',
                        }}
                      >
                        {errors.ankle?.message}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontWeight: '600', fontSize: '15px'}}
                      >
                        Rise
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontSize: '13px', color: '#332FD0'}}
                        onClick={handleOpen}
                      >
                        Look at photo attached
                      </Typography>
                      <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box sx={style}>
                          <img src={rise} />
                        </Box>
                      </Modal>
                    </TableCell>

                    <TableCell>
                      <TextField
                        InputProps={{
                          disableUnderline: true,
                        }}
                        variant="standard"
                        {...register('rise')}
                        className="tableInput"
                        type="text"
                      />

                      <Typography
                        sx={{
                          marginTop: '3%',
                          color: 'red',
                          fontSize: '12px',

                          width: '50%',
                        }}
                      >
                        {errors.rise?.message}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontWeight: '600', fontSize: '15px'}}
                      >
                        Height
                      </Typography>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      <Box style={{display: 'flex'}}>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '25%',
                          }}
                        >
                          <TextField
                            InputProps={{
                              disableUnderline: true,
                            }}
                            variant="standard"
                            sx={{marginRight: '2%', width: '100%'}}
                            {...register('height1')}
                            className="tableInput"
                            type="text"
                          />
                          <Typography>ft</Typography>
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '25%',
                          }}
                        >
                          <TextField
                            InputProps={{
                              disableUnderline: true,
                            }}
                            variant="standard"
                            sx={{marginLeft: '2%', width: '100%'}}
                            {...register('height2')}
                            className="tableInput"
                            type="text"
                          />
                          <Typography>in </Typography>
                        </Box>
                      </Box>
                      <Typography
                        sx={{
                          marginTop: '3%',
                          color: 'red',
                          fontSize: '12px',

                          width: '50%',
                        }}
                      >
                        {errors.height2?.message && errors.height1?.message ? (
                          <></>
                        ) : (
                          errors.height1?.message
                        )}
                      </Typography>
                      <Typography
                        sx={{
                          marginTop: '3%',
                          color: 'red',
                          fontSize: '12px',

                          width: '50%',
                        }}
                      >
                        {errors.height2?.message}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontWeight: '600', fontSize: '15px'}}
                      >
                        American Suit Size
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontSize: '13px'}}
                      >
                        For example, 42 Reg, 38 Short
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Box style={{display: 'flex'}}>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '25%',
                          }}
                        >
                          <TextField
                            InputProps={{
                              disableUnderline: true,
                            }}
                            variant="standard"
                            placeholder="e.g. 36"
                            sx={{marginRight: '2%', width: '100%'}}
                            {...register('suitSize')}
                            className="tableInput"
                            type="text"
                          />
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '25%',
                          }}
                        >
                          <TextField
                            InputProps={{
                              disableUnderline: true,
                            }}
                            variant="standard"
                            placeholder="e.g. R"
                            sx={{marginLeft: '2%', width: '100%'}}
                            {...register('suitSize2')}
                            className="tableInput"
                            type="text"
                          />
                        </Box>
                      </Box>
                      <Typography
                        sx={{
                          marginTop: '3%',
                          color: 'red',
                          fontSize: '12px',

                          width: '50%',
                        }}
                      >
                        {errors.suitSize2?.message && errors.suitSize?.message
                          ? errors.suitSize?.message
                          : errors.suitSize?.message}
                      </Typography>
                      <Typography
                        sx={{
                          marginTop: '3%',
                          color: 'red',
                          fontSize: '12px',

                          width: '50%',
                        }}
                      >
                        {errors.suitSize2?.message}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontWeight: '600', fontSize: '15px'}}
                      >
                        American Shoe Size
                      </Typography>
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontWeight: '400', fontSize: '10px'}}
                      >
                        (If ordered)
                      </Typography>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      <TextField
                        InputProps={{
                          disableUnderline: true,
                        }}
                        variant="standard"
                        {...register('shoeSize')}
                        className="tableInput"
                        type="text"
                      />
                      <Typography
                        sx={{
                          marginTop: '3%',
                          color: 'red',
                          fontSize: '12px',

                          width: '50%',
                        }}
                      >
                        {errors.shoeSize?.message}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontWeight: '600', fontSize: '15px'}}
                      >
                        Extra Notes
                      </Typography>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      <TextField
                        InputProps={{
                          disableUnderline: true,
                        }}
                        multiline
                        sx={{width: '100%'}}
                        rows={5}
                        variant="standard"
                        {...register('extraNotes')}
                        className="tableInput"
                        type="text"
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <Grid container sx={{marginTop: '5%', justifyContent: 'center'}}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Button
              type="submit"
              fullWidth
              sx={{
                borderRadius: '0',
                '&:hover': {
                  backgroundColor: '#c16452',
                },
                backgroundColor: '#212b36',
              }}
              variant="contained"
              onClick={handleSubmit(onSubmit)}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Kurta;
