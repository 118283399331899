import { Container, Grid, Typography } from "@mui/material";
import React from "react";
import Video from "../Video";

const Thankyou = ({submit}) => {
  return (
    <>
      <Container sx={{ textAlign: "center" }}>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={8} paddingTop={{ xs: 12, sm: 16, md: 20 }}>
            <Typography variant="h6" sx={{ fontWeight: 600 }}>
              Your measurements were submitted successfully.
            </Typography>
          </Grid>
          <Grid item xs={12} paddingTop="5%">
            <Video submit={submit} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Thankyou;
