import React, {Fragment, useContext} from 'react';
import {Grid} from '@mui/material';
import {GlobalContext} from '../../../../context/GlobalContext';
import {videos} from '../../../../commonVariables/commonVariables';

const Video = ({submit}) => {
  const {form} = useContext(GlobalContext);
  const videosToDisplay = submit ? videos.thankyou : videos[form.toLowerCase()];
  return (
    <>
      <Grid container justifyContent="center">
        {videosToDisplay.map((video, index) => {
          return (
            <Fragment key={index}>
              <Grid
                item
                xs={12}
                sm={10}
                md={videosToDisplay.length > 1 ? 6 : 8}
                padding={videosToDisplay.length > 1 ? 2 : 0}
              >
                <iframe
                  src={`https://www.youtube.com/embed/${video}`}
                  width="100%"
                  height="300px"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </Grid>
            </Fragment>
          );
        })}
      </Grid>
    </>
  );
};

export default Video;
