import React, { useEffect } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  Input,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import moment from "moment";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
const today = moment(new Date());
const schema = yup.object().shape({
  firstName: yup.string().required("First name is required."),
  lastName: yup.string().required("Last name is required."),
  form: yup.string().required("Form is required."),
  phoneNumber: yup.string().required("Phone number is required."),
  friendsLimit: yup
    .number()
    .required("Friends Limit should be a number.")
    .typeError("Friends Limit should be a number."),
  email: yup
    .string()
    .email("Wrong email format.")
    .required("Email is required."),
  eventDate: yup
    .date()
    .min(moment(today), "Event date should not be past date.")
    .required()
    .typeError("Event date should not be past date."),
});
const AddClient = ({ setSelectedComponent }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });
  const onSubmit = async (data) => {
    if (data.form != "select") {
      const formData = {
        first_name: data.firstName,
        last_name: data.lastName,
        date: data.eventDate,
        form: data.form,
        phone_number: data.phoneNumber,
        friends_allowed: data.friendsLimit,
        email: data.email,
      };

      await axios
        .post("/api/client/add", { formData })
        .then((res) => {
          if (res.data.code == 200) {
            if (res.data.data.message == "Client already registered!") {
              toast.error(res.data.data.message, {
                style: {
                  color: "white",
                },
              });
            } else {
              toast.success(res.data.data.message, {
                style: {
                  color: "white",
                },
              });
              window.location.reload();
              return false;
            }
          } else {
            toast.error(res.data.error, {
              style: {
                color: "white",
              },
            });
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error("An error occured while adding client. Try again!", {
            style: {
              color: "white",
            },
          });
        });
    } else {
      toast.error("Form is required.", {
        style: {
          color: "white",
        },
      });
    }
  };

  useEffect(() => {
    setSelectedComponent("Add Client");
  }, []);
  return (
    <>
      <Toaster
        position="top-right"
        toastOptions={{
          success: {
            style: {
              background: "green",
            },
          },
          error: {
            style: {
              background: "red",
            },
          },
        }}
      />
      <Box
        sx={{
          width: "100%",
          justifyContent: "center",
          marginBottom: "15px",
        }}
      >
        <Container className="container">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid
              container
              className="paddingTop"
              sx={{
                marginTop: "3%",
                width: "100%",
                margin: 0,
                padding: "5%",
              }}
            >
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                sx={{ textAlign: "center", padding: "3%" }}
              >
                <Box>
                  <InputLabel sx={{ textAlign: "left", color: "#1b1a17" }}>
                    First Name
                  </InputLabel>
                  <Input
                    type="text"
                    sx={{ width: "100%" }}
                    {...register("firstName", { required: true })}
                  />
                  <Typography
                    sx={{
                      textAlign: "left",
                      marginTop: "3%",
                      color: "red",
                      fontSize: "12px",
                    }}
                  >
                    {errors.firstName?.message}
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                sx={{ textAlign: "center", padding: "3%" }}
              >
                <Box>
                  <InputLabel sx={{ textAlign: "left", color: "#1b1a17" }}>
                    Last Name
                  </InputLabel>
                  <Input
                    type="text"
                    sx={{ width: "100%" }}
                    {...register("lastName", { required: true })}
                  />
                  <Typography
                    sx={{
                      textAlign: "left",
                      marginTop: "3%",
                      color: "red",
                      fontSize: "12px",
                    }}
                  >
                    {errors.lastName?.message}
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                sx={{ textAlign: "center", padding: "3%" }}
              >
                <Box>
                  <InputLabel sx={{ textAlign: "left", color: "#1b1a17" }}>
                    Event Date
                  </InputLabel>
                  <Input
                    type="date"
                    sx={{ width: "100%" }}
                    {...register("eventDate", { required: true })}
                  />
                  <Typography
                    sx={{
                      textAlign: "left",
                      marginTop: "3%",
                      color: "red",
                      fontSize: "12px",
                    }}
                  >
                    {errors.eventDate?.message}
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                sx={{ textAlign: "center", padding: "3%" }}
              >
                <Box>
                  <InputLabel sx={{ textAlign: "left", color: "#1b1a17" }}>
                    Email
                  </InputLabel>
                  <Input
                    type="email"
                    sx={{ width: "100%" }}
                    {...register("email", { required: true })}
                  />
                  <Typography
                    sx={{
                      textAlign: "left",
                      marginTop: "3%",
                      color: "red",
                      fontSize: "12px",
                    }}
                  >
                    {errors.email?.message}
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                sx={{ textAlign: "center", padding: "3%" }}
              >
                <Box>
                  <InputLabel sx={{ textAlign: "left", color: "#1b1a17" }}>
                    Form
                  </InputLabel>
                  <Select
                    variant="standard"
                    defaultValue=""
                    sx={{ width: "100%", textAlign: "left" }}
                    {...register("form", { required: true })}
                  >
                    <MenuItem value="Kurta">
                      <em>GMen</em>
                    </MenuItem>
                    <MenuItem value="Anarkali">
                      <em>Bridesmaids Anarkali</em>
                    </MenuItem>
                    <MenuItem value="Blouse">
                      <em>Bridesmaids Blouse and Prepleating</em>
                    </MenuItem>
                    <MenuItem value="Bridesmaids">
                      <em>Bridesmaids Blouse </em>
                    </MenuItem>
                    <MenuItem value="Lehanga">
                      <em>Bridesmaids Lehenga Choli </em>
                    </MenuItem>
                    <MenuItem value="Palazzo">
                      <em>Womens Palazzo Dhoti</em>
                    </MenuItem>
                  </Select>
                  <Typography
                    sx={{
                      textAlign: "left",
                      marginTop: "3%",
                      color: "red",
                      fontSize: "12px",
                    }}
                  >
                    {errors.form?.message}
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                sx={{ textAlign: "center", padding: "3%" }}
              >
                <Box>
                  <InputLabel sx={{ textAlign: "left", color: "#1b1a17" }}>
                    Phone Number
                  </InputLabel>
                  <Input
                    type="tel"
                    sx={{ width: "100%" }}
                    {...register("phoneNumber", { required: true })}
                  />
                  <Typography
                    sx={{
                      textAlign: "left",
                      marginTop: "3%",
                      color: "red",
                      fontSize: "12px",
                    }}
                  >
                    {errors.phoneNumber?.message}
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                sx={{ textAlign: "center", padding: "3%" }}
              >
                <Box>
                  <InputLabel sx={{ textAlign: "left", color: "#1b1a17" }}>
                    Friends Limit
                  </InputLabel>
                  <Input
                    type="number"
                    sx={{ width: "100%" }}
                    {...register("friendsLimit", { required: true })}
                  />
                  <Typography
                    sx={{
                      textAlign: "left",
                      marginTop: "3%",
                      color: "red",
                      fontSize: "12px",
                    }}
                  >
                    {errors.friendsLimit?.message}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={4} sx={{ textAlign: "center" }}>
              <Box>
                <Button
                  type="submit"
                  sx={{
                    borderRadius: "0",
                    "&:hover": {
                      backgroundColor: "#c16452",
                    },
                    backgroundColor: "#212b36",
                  }}
                  variant="contained"
                >
                  Add Client
                </Button>
              </Box>
            </Grid>
          </form>
        </Container>
      </Box>
    </>
  );
};

export default AddClient;
