import React, { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import "./styles.css";
import axios from "axios";

const AdminLogin = ({ setIsLoggedIn, navigate }) => {
  const [password, setPassword] = useState("");
  const login = (e) => {
    e.preventDefault();
    if (password) {
      axios.post("/api/admin/checkPassword", { password }).then((res) => {
        if (res.data.code === 200) {
          setIsLoggedIn(true);
          navigate("/admin-dashboard");
        } else {
          toast.error("Invalid password! Try again.", {
            style: {
              color: "white",
            },
          });
        }
      });
    } else {
      toast.error("Password cannot be empty!", {
        style: {
          color: "white",
        },
      });
    }
  };
  return (
    <>
      <Toaster
        position="top-right"
        toastOptions={{
          success: {
            style: {
              background: "green",
            },
          },
          error: {
            style: {
              background: "red",
            },
          },
        }}
      />
      <Container
        sx={{ minHeight: "657px", display: "flex", alignItems: "center" }}
      >
        <Grid
          container
          justifyContent="center"
          p={2}
          sx={{ height: "100%", alignItems: "center" }}
        >
          <Grid item xs={12} sm={6} md={4}>
            <Box>
              <Card sx={{ backgroundColor: "#f7f1f0" }}>
                <CardHeader
                  title={
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="h2"
                      sx={{ color: "#fff", fontWeight: "700" }}
                    >
                      Admin Login
                    </Typography>
                  }
                  sx={{
                    backgroundColor: "#212b36",
                    textAlign: "center",
                    paddingY: "10%",
                  }}
                />
                <CardContent>
                  <form onSubmit={(e) => login(e)}>
                    <Grid
                      sx={{ paddingY: "15%" }}
                      container
                      direction="column"
                      rowSpacing={3}
                    >
                      <Grid item>
                        <TextField
                          fullWidth
                          type="password"
                          label="Password"
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </Grid>
                      <Grid item>
                        <Button
                          type="submit"
                          variant="contained"
                          sx={{
                            borderRadius: "0",
                            "&:hover": {
                              backgroundColor: "#c16452",
                            },
                            backgroundColor: "#212b36",
                          }}
                        >
                          Login
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </CardContent>
              </Card>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default AdminLogin;
