import {
  Box,
  FormGroup,
  Grid,
  TextField,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
  List,
  ListItem,
} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import Modal from '@mui/material/Modal';
import React, {useContext, useState, useEffect} from 'react';
import './styles.css';
import toast, {Toaster} from 'react-hot-toast';
import {useForm} from 'react-hook-form';
import {GlobalContext} from '../../../../../../context/GlobalContext';
import axios from 'axios';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '15px',
  textAlign: 'center',
};
const schema1 = yup.object().shape({
  lengthWithHeels: yup
    .number('Length With Heels should be a number.')
    .required('Length With Heels should be a number.')
    .typeError('Length With Heels should be a number.'),
  pads: yup.string().required('Pads is required.'),
  blouseLength: yup
    .number('Blouse Length should be a number between.')
    .required('Blouse Length should be a number between.')
    .typeError('Blouse Length should be a number between.'),
  chest: yup
    .number('Chest should be a number.')
    .required('Chest should be a number.')
    .typeError('Chest should be a number.'),
  lowerChest: yup
    .number('Lower Chest should be a number.')
    .required('Lower Chest should be a number.')
    .typeError('Lower Chest should be a number.'),
  hips: yup
    .number('Hips should be a number.')
    .required('Hips should be a number.')
    .typeError('Hips should be a number.'),
  neckDepthFront: yup
    .number('Neck Depth Front should be a number.')
    .required('Neck Depth Front should be a number.')
    .typeError('Neck Depth Front should be a number.'),
  neckDepthBack: yup
    .number('Neck Depth Back should be a number.')
    .required('Neck Depth Back should be a number.')
    .typeError('Neck Depth Back should be a number.'),
  sleeveRound: yup
    .number('Sleeve Round should be a number.')
    .required('Sleeve Round should be a number.')
    .typeError('Sleeve Round should be a number.'),
  upperChest: yup
    .number('Upper Chest should be a number.')
    .required('Upper Chest should be a number.')
    .typeError('Upper Chest should be a number.'),
  shoulder: yup
    .number('Shoulder should be a number between.')
    .required('Shoulder should be a number between.')
    .typeError('Shoulder should be a number between.'),
  sleeveLength: yup
    .number('Sleeve Length should be a number.')
    .required('Sleeve Length should be a number.')
    .typeError('Sleeve Length should be a number.'),
  underarm: yup
    .number('Uderarm should be a number.')
    .required('Uderarm should be a number.')
    .typeError('Uderarm should be a number.'),
  waist1: yup
    .number('Waist should be a number.')
    .required('Waist should be a number.')
    .typeError('Waist should be a number.'),
  name: yup.string().required('Name is required.'),
  waist2: yup
    .number('Waist should be a number.')
    .required('Waist should be a number.')
    .typeError('Waist should be a number.'),
  name: yup.string().required('Name is required.'),
  email: yup
    .string()
    .email('Wrong email format.')
    .required('Email is required.'),
});
const schema2 = yup.object().shape({
  lengthWithHeels: yup
    .number('Length With Heels should be a number between 39 and 46 inches.')
    .min(39, 'Length With Heels should be a number between 39 and 46 inches.')
    .max(46, 'Length With Heels should be a number between 39 and 46 inches.')
    .typeError(
      'Length With Heels should be a number between 39 and 46 inches.'
    ),
  pads: yup.string().oneOf(['Y', 'N', 'y', 'n'], 'Pads must be Y or N'),
  blouseLength: yup
    .number('Blouse Length should be a number between 14 and 20 inches.')
    .min(14, 'Blouse Length should be a number between 14 and 20 inches.')
    .max(20, 'Blouse Length should be a number between 14 and 20 inches.')
    .typeError('Blouse Length should be a number between 14 and 20 inches.'),
  chest: yup
    .number('Chest should be a number.')
    .positive('Chest should be a number.')
    .typeError('Chest should be a number.'),
  lowerChest: yup
    .number('Lower Chest should be a number.')
    .positive('Lower Chest should be a number.')
    .typeError('Lower Chest should be a number.'),
  hips: yup
    .number('Hips should be a number.')
    .positive('Hips should be a number.')
    .typeError('Hips should be a number.'),
  neckDepthFront: yup
    .number('Neck Depth Front should be a number.')
    .positive('Neck Depth Front should be a number.')
    .typeError('Neck Depth Front should be a number.'),
  neckDepthBack: yup
    .number('Neck Depth Back should be a number.')
    .positive('Neck Depth Back should be a number.')
    .typeError('Neck Depth Back should be a number.'),
  sleeveRound: yup
    .number('Sleeve Round should be a number.')
    .positive('Sleeve Round should be a number.')
    .typeError('Sleeve Round should be a number.'),
  upperChest: yup
    .number('Upper Chest should be a number.')
    .positive('Upper Chest should be a number.')
    .typeError('Upper Chest should be a number.'),
  shoulder: yup
    .number('Shoulder should be a number between 14 and 20 inches.')
    .min(14, 'Shoulder should be a number between 14 and 20 inches.')
    .max(20, 'Shoulder should be a number between 14 and 20 inches.')
    .typeError('Shoulder should be a number between 14 and 20 inches.'),
  sleeveLength: yup
    .number('Sleeve Length should be a number between 3 and 24 inches.')
    .min(3, 'Sleeve Length should be a number between 3 and 24 inches.')
    .max(24, 'Sleeve Length should be a number between 3 and 24 inches.')
    .typeError('Sleeve Length should be a number between 3 and 24 inches.'),
  underarm: yup
    .number('Uderarm should be a number.')
    .positive('Uderarm should be a number.')
    .typeError('Uderarm should be a number.'),
  waist1: yup
    .number('Waist should be a number.')
    .positive('Waist should be a number.')
    .typeError('Waist should be a number.'),
  name: yup.string().required('Name is required.'),
  waist2: yup
    .number('Waist should be a number.')
    .positive('Waist should be a number.')
    .typeError('Waist should be a number.'),
  name: yup.string().required('Name is required.'),
  email: yup
    .string()
    .email('Wrong email format.')
    .required('Email is required.'),
});
const Lehanga = ({setSubmit}) => {
  const [open, setOpen] = useState(false);
  const [rangeErrors, setRangeErrors] = useState();
  const [errorToDisplay, setErrorToDisplay] = useState({});
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const {clientId} = useContext(GlobalContext);
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm({resolver: yupResolver(schema1)});
  const send = async (data) => {
    const formData = {
      reference_client_id: clientId,
      email: data.email,
      name: data.name,
      measurements: {...data},
    };
    await axios.post('/api/friend/register', {formData}).then((res) => {
      if (res.data.data) {
        toast.error(res.data.data.message, {
          style: {
            color: 'white',
          },
        });
      } else {
        if (res.data.code == 200) {
          setSubmit(true);
        } else {
          toast.error(
            'There was an error saving your measurements. Try again!',
            {
              style: {
                color: 'white',
              },
            }
          );
        }
      }
    });
  };
  useEffect(() => {
    if (rangeErrors) {
      const inners = rangeErrors.inner;
      const tempObj = {};
      for (let key in inners) {
        const errorObj = inners[key];
        const path = errorObj.path;
        const message = errorObj.message;
        tempObj[path] = message;
      }
      setErrorToDisplay(tempObj);
    }
  }, [rangeErrors]);
  const onSubmit = async (data) => {
    try {
      await schema2.validate(data, {abortEarly: false});
      send(data);
    } catch (err) {
      setRangeErrors(err);
      handleOpen();
    }
  };
  return (
    <>
      <Toaster
        position="top-right"
        toastOptions={{
          success: {
            style: {
              background: 'green',
            },
          },
          error: {
            style: {
              background: 'red',
            },
          },
        }}
      />
      <Box sx={{paddingBottom: '5%'}}>
        {' '}
        <Grid container marginTop={'5%'} justifyContent="center">
          <Grid item xs={10} sm={8} md={12} marginTop={'2%'}>
            <Typography variant="h6" sx={{fontWeight: 700}}>
              Note:
            </Typography>
            <List sx={{display: 'list-item', listStyle: 'none'}}>
              <ListItem>
                <CircleIcon sx={{height: '10px'}} />
                <Typography variant="p">
                  Please put all measurements in inches
                </Typography>
              </ListItem>
              <ListItem>
                <CircleIcon sx={{height: '10px'}} />
                <Typography variant="p">
                  Alterations are an additional charge
                </Typography>
              </ListItem>
              <ListItem>
                <CircleIcon sx={{height: '10px'}} />
                <Typography variant="p">
                  Kindly watch the instruction video while measuring yourself
                </Typography>
              </ListItem>
            </List>
          </Grid>
        </Grid>
        <Grid container marginTop={'5%'}>
          <Grid item xs={8} sm={6} md={4} lg={3} marginTop={'2%'}>
            <FormGroup sx={{marginLeft: '10px'}}>
              <InputLabel
                sx={{fontWeight: '700', textAlign: 'left', color: '#1b1a17'}}
              >
                Name
              </InputLabel>
              <TextField variant="standard" type="text" {...register('name')} />
              <Typography
                sx={{
                  marginTop: '3%',
                  color: 'red',
                  fontSize: '12px',
                }}
              >
                {errors.name?.message}
              </Typography>
            </FormGroup>
          </Grid>
          <Grid item xs={8} sm={6} md={4} lg={3} marginTop={'2%'}>
            <FormGroup sx={{marginLeft: '10px'}}>
              <InputLabel
                sx={{fontWeight: '700', textAlign: 'left', color: '#1b1a17'}}
              >
                Email
              </InputLabel>
              <TextField
                variant="standard"
                type="email"
                {...register('email')}
              />
              <Typography
                sx={{
                  marginTop: '3%',
                  color: 'red',
                  fontSize: '12px',
                }}
              >
                {errors.email?.message}
              </Typography>
            </FormGroup>
          </Grid>
        </Grid>
        <Grid container marginTop={'2%'}>
          <Grid item xs={12} lg={12}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        backgroundColor: '#212b36',
                        color: '#FFF',
                        border: 'none',
                        padding: '20px',
                      }}
                    >
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontWeight: '700', fontSize: '15px'}}
                      >
                        Measurements needed
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        backgroundColor: '#212b36',
                        color: '#FFF',
                        border: 'none',
                        padding: '20px',
                      }}
                    >
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontWeight: '700', fontSize: '15px'}}
                      >
                        Measurement Description
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        backgroundColor: '#212b36',
                        color: '#FFF',
                        border: 'none',
                        padding: '20px',
                      }}
                    >
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontWeight: '700', fontSize: '15px'}}
                      >
                        Measurement Ranges
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        backgroundColor: '#212b36',
                        color: '#FFF',
                        border: 'none',
                        padding: '20px',
                      }}
                    >
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontWeight: '700', fontSize: '15px'}}
                      >
                        Measurements
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell variant="head">
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontWeight: '600', fontSize: '15px'}}
                      >
                        Upper chest
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontSize: '13px'}}
                      >
                        Circumference above chest
                      </Typography>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      <TextField
                        InputProps={{
                          disableUnderline: true,
                        }}
                        type="text"
                        variant="standard"
                        {...register('upperChest')}
                        className="tableInput"
                      />{' '}
                      <Typography
                        sx={{
                          marginTop: '3%',
                          color: 'red',
                          width: '50%',
                          fontSize: '12px',
                        }}
                      >
                        {errors.upperChest?.message}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontWeight: '600', fontSize: '15px'}}
                      >
                        Chest
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontSize: '13px'}}
                      >
                        Circumference around chest
                      </Typography>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      <TextField
                        InputProps={{
                          disableUnderline: true,
                        }}
                        type="text"
                        variant="standard"
                        {...register('chest')}
                        className="tableInput"
                      />{' '}
                      <Typography
                        sx={{
                          marginTop: '3%',
                          color: 'red',
                          width: '50%',
                          fontSize: '12px',
                        }}
                      >
                        {errors.chest?.message}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontWeight: '600', fontSize: '15px'}}
                      >
                        Lower Chest
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontSize: '13px'}}
                      >
                        Circumference underneath the chest
                      </Typography>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      <TextField
                        InputProps={{
                          disableUnderline: true,
                        }}
                        type="text"
                        variant="standard"
                        {...register('lowerChest')}
                        className="tableInput"
                      />{' '}
                      <Typography
                        sx={{
                          marginTop: '3%',
                          color: 'red',
                          width: '50%',
                          fontSize: '12px',
                        }}
                      >
                        {errors.lowerChest?.message}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontWeight: '600', fontSize: '15px'}}
                      >
                        Blouse length
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontSize: '13px'}}
                      >
                        From shoulder to however long you want it
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontSize: '13px'}}
                      >
                        14-20 in
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <TextField
                        InputProps={{
                          disableUnderline: true,
                        }}
                        type="text"
                        variant="standard"
                        {...register('blouseLength')}
                        className="tableInput"
                      />{' '}
                      <Typography
                        sx={{
                          marginTop: '3%',
                          color: 'red',
                          width: '50%',
                          fontSize: '12px',
                        }}
                      >
                        {errors.blouseLength?.message}
                        {errorToDisplay.blouseLength}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontWeight: '600', fontSize: '15px'}}
                      >
                        Shoulder
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontSize: '13px'}}
                      >
                        Shoulder to Shoulder
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontSize: '13px'}}
                      >
                        14-20 in
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <TextField
                        InputProps={{
                          disableUnderline: true,
                        }}
                        type="text"
                        variant="standard"
                        {...register('shoulder')}
                        className="tableInput"
                      />{' '}
                      <Typography
                        sx={{
                          marginTop: '3%',
                          color: 'red',
                          width: '50%',
                          fontSize: '12px',
                        }}
                      >
                        {errors.shoulder?.message}
                        {errorToDisplay.shoulder}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontWeight: '600', fontSize: '15px'}}
                      >
                        Waist
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontSize: '13px'}}
                      >
                        Circumference where blouse ends
                      </Typography>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      <TextField
                        InputProps={{
                          disableUnderline: true,
                        }}
                        type="text"
                        variant="standard"
                        {...register('waist1')}
                        className="tableInput"
                      />{' '}
                      <Typography
                        sx={{
                          marginTop: '3%',
                          color: 'red',
                          width: '50%',
                          fontSize: '12px',
                        }}
                      >
                        {errors.waist1?.message}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontWeight: '600', fontSize: '15px'}}
                      >
                        Sleeve length
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontSize: '13px'}}
                      >
                        From shoulder to however long you want it
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontSize: '13px'}}
                      >
                        For cap sleeves= 3-5 inches
                      </Typography>
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontSize: '13px'}}
                      >
                        For elbow length sleeve = 10-11 inches
                      </Typography>
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontSize: '13px'}}
                      >
                        For 3/4 sleeve= 15-17 inches
                      </Typography>
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontSize: '13px'}}
                      >
                        For full sleeves= 19-24 inches
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <TextField
                        InputProps={{
                          disableUnderline: true,
                        }}
                        type="text"
                        variant="standard"
                        {...register('sleeveLength')}
                        className="tableInput"
                      />{' '}
                      <Typography
                        sx={{
                          marginTop: '3%',
                          color: 'red',
                          width: '50%',
                          fontSize: '12px',
                        }}
                      >
                        {errors.sleeveLength?.message}
                        {errorToDisplay.sleeveLength}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontWeight: '600', fontSize: '15px'}}
                      >
                        Sleeve round
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontSize: '13px'}}
                      >
                        Circumference around bicep
                      </Typography>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      <TextField
                        InputProps={{
                          disableUnderline: true,
                        }}
                        type="text"
                        variant="standard"
                        {...register('sleeveRound')}
                        className="tableInput"
                      />{' '}
                      <Typography
                        sx={{
                          marginTop: '3%',
                          color: 'red',
                          width: '50%',
                          fontSize: '12px',
                        }}
                      >
                        {errors.sleeveRound?.message}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontWeight: '600', fontSize: '15px'}}
                      >
                        Underarm/armpit
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontSize: '13px'}}
                      >
                        Circumference where arm meets shoulder
                      </Typography>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      <TextField
                        InputProps={{
                          disableUnderline: true,
                        }}
                        type="text"
                        variant="standard"
                        {...register('underarm')}
                        className="tableInput"
                      />{' '}
                      <Typography
                        sx={{
                          marginTop: '3%',
                          color: 'red',
                          width: '50%',
                          fontSize: '12px',
                        }}
                      >
                        {errors.underarm?.message}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontWeight: '600', fontSize: '15px'}}
                      >
                        Neck depth front
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontSize: '13px'}}
                      >
                        Measure diagonally, from shoulder to cleavage
                      </Typography>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      <TextField
                        InputProps={{
                          disableUnderline: true,
                        }}
                        type="text"
                        variant="standard"
                        {...register('neckDepthFront')}
                        className="tableInput"
                      />{' '}
                      <Typography
                        sx={{
                          marginTop: '3%',
                          color: 'red',
                          width: '50%',
                          fontSize: '12px',
                        }}
                      >
                        {errors.neckDepthFront?.message}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontWeight: '600', fontSize: '15px'}}
                      >
                        Neck depth back
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontSize: '13px'}}
                      >
                        Measure diagonally, from shoulder to how low you want it
                      </Typography>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      <TextField
                        InputProps={{
                          disableUnderline: true,
                        }}
                        type="text"
                        variant="standard"
                        {...register('neckDepthBack')}
                        className="tableInput"
                      />{' '}
                      <Typography
                        sx={{
                          marginTop: '3%',
                          color: 'red',
                          width: '50%',
                          fontSize: '12px',
                        }}
                      >
                        {errors.neckDepthBack?.message}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontWeight: '600', fontSize: '15px'}}
                      >
                        Do you want Pads?
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontSize: '13px'}}
                      >
                        Yes or No
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontSize: '13px'}}
                      >
                        Y or N
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <TextField
                        InputProps={{
                          disableUnderline: true,
                        }}
                        type="text"
                        variant="standard"
                        {...register('pads')}
                        className="tableInput"
                      />{' '}
                      <Typography
                        sx={{
                          marginTop: '3%',
                          color: 'red',
                          width: '50%',
                          fontSize: '12px',
                        }}
                      >
                        {errors.pads?.message}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontWeight: '600', fontSize: '15px'}}
                      >
                        Waist
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontSize: '13px'}}
                      >
                        Circumference around belly button
                      </Typography>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      <TextField
                        InputProps={{
                          disableUnderline: true,
                        }}
                        type="text"
                        variant="standard"
                        {...register('waist2')}
                        className="tableInput"
                      />{' '}
                      <Typography
                        sx={{
                          marginTop: '3%',
                          color: 'red',
                          width: '50%',
                          fontSize: '12px',
                        }}
                      >
                        {errors.waist2?.message}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontWeight: '600', fontSize: '15px'}}
                      >
                        Hips
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontSize: '13px'}}
                      >
                        Circumference over butt
                      </Typography>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      <TextField
                        InputProps={{
                          disableUnderline: true,
                        }}
                        type="text"
                        variant="standard"
                        {...register('hips')}
                        className="tableInput"
                      />{' '}
                      <Typography
                        sx={{
                          marginTop: '3%',
                          color: 'red',
                          width: '50%',
                          fontSize: '12px',
                        }}
                      >
                        {errors.hips?.message}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontWeight: '600', fontSize: '15px'}}
                      >
                        Length with heels
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontSize: '13px'}}
                      >
                        Length from belly button to floor plus heels
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontSize: '13px'}}
                      >
                        39-46 inches
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <TextField
                        InputProps={{
                          disableUnderline: true,
                        }}
                        type="text"
                        variant="standard"
                        {...register('lengthWithHeels')}
                        className="tableInput"
                      />{' '}
                      <Typography
                        sx={{
                          marginTop: '3%',
                          color: 'red',
                          width: '50%',
                          fontSize: '12px',
                        }}
                      >
                        {errors.lengthWithHeels?.message}
                        {errorToDisplay.lengthWithHeels}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">
                      <Typography
                        variant="p"
                        component="div"
                        sx={{fontWeight: '600', fontSize: '15px'}}
                      >
                        Extra Notes
                      </Typography>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell colSpan={2}>
                      <TextField
                        InputProps={{
                          disableUnderline: true,
                        }}
                        multiline
                        sx={{width: '100%'}}
                        rows={5}
                        variant="standard"
                        {...register('extraNotes')}
                        className="tableInput"
                        type="text"
                      />
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <Grid container sx={{marginTop: '5%', justifyContent: 'center'}}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Button
              type="submit"
              fullWidth
              sx={{
                borderRadius: '0',
                '&:hover': {
                  backgroundColor: '#c16452',
                },
                backgroundColor: '#212b36',
              }}
              variant="contained"
              onClick={handleSubmit(onSubmit)}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
        {open ? (
          <Grid container sx={{marginTop: '5%', justifyContent: 'center'}}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Modal open={open} onClose={handleClose}>
                <Box
                  p={{xs: 3, sm: 5}}
                  width={{xs: '70%', sm: '60%', md: '50%'}}
                  sx={{...style}}
                >
                  <Typography variant="h6">
                    Some values are out of the recommended range, are you sure
                    you want to submit these values?
                  </Typography>
                  <Box marginTop={{xs: 2}}>
                    <Button
                      sx={{margin: '3%'}}
                      variant="contained"
                      color="error"
                      onClick={handleSubmit(send)}
                    >
                      I am sure, submit
                    </Button>
                    <Button
                      sx={{margin: '3%'}}
                      variant="contained"
                      color="primary"
                      onClick={handleClose}
                    >
                      Let me edit again
                    </Button>
                  </Box>
                </Box>
              </Modal>
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
      </Box>
    </>
  );
};

export default Lehanga;
